import deprecated from "../deprecated";

const getSpinner = () => document.getElementById("ajaxLoaderDiv");
const getInfoContainer = () => document.getElementById("ajaxLoaderInfoTekst");

const exists = () => getSpinner() != null;

const createSpinner = (infoText = "Vennligst vent...") => {
  const spinner = document.createElement("div");
  spinner.id = "ajaxLoaderDiv";
  Object.assign(spinner.style, {
    bottom: "0px",
    display: "none",
    left: "0px",
    minHeight: "100%",
    minWidth: "100%",
    position: "fixed",
    top: "0px",
    zIndex: "50",
  });
  spinner.addEventListener("mousedown", (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  });

  const shaddow = document.createElement("div");
  Object.assign(shaddow.style, {
    backgroundColor: "#ddd",
    height: "100%",
    left: "0px",
    opacity: "0.01",
    position: "absolute",
    top: "0px",
    width: "100%",
  });
  spinner.appendChild(shaddow);

  const loaderDiv = document.createElement("div");
  Object.assign(loaderDiv.style, {
    backgroundColor: "white",
    border: "1px solid #666",
    borderRadius: "10px",
    boxShadow: "0px 0px 15px black",
    left: "calc((100% - 300px) / 2)",
    minWidth: "300px",
    paddingBottom: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    position: "absolute",
    textAlign: "center",
    top: "calc((100% - 100px) / 2)",
    zIndex: "2",
  });
  spinner.appendChild(loaderDiv);

  const img = document.createElement("img");
  img.src = "/db/bilder/ajax-loader-ny.gif";
  img.style.marginTop = "25px";
  img.style.width = "48px";

  const textLine1 = document.createElement("p");
  textLine1.id = "ajaxLoaderInfoTekst";
  textLine1.innerText = infoText;
  Object.assign(textLine1.style, {
    marginLeft: "0px",
    textAlign: "center",
  });

  const textLine2 = document.createElement("p");
  textLine2.innerText = "";
  textLine2.classList.add("graa");
  Object.assign(textLine2, {
    display: "",
    marginLeft: "0px",
    textAlign: "center",
  });

  loaderDiv.appendChild(img);
  loaderDiv.appendChild(textLine1);
  loaderDiv.appendChild(textLine2);
  document.body.appendChild(spinner);
  return spinner;
};

const updateText = (newText: string) => {
  getInfoContainer()!.innerText = newText;
};

const showSpinner = () => {
  getSpinner()!.style.display = "";
};

const hideSpinner = () => {
  getSpinner()!.style.display = "none";
};

const stack: string[] = [];

export const push = (msg = "Vennligst vent...") => {
  stack.push(msg);
  if (stack.length === 1) {
    if (!exists()) {
      createSpinner(msg);
    } else {
      updateText(msg);
    }

    showSpinner();
  } else {
    updateText(msg);
  }
};

export const pop = () => {
  if (stack.length === 0) {
    throw new Error("Spinner push/pop stack unballance");
  }

  stack.pop();
  if (stack.length > 0) {
    updateText(stack[stack.length - 1]);
  } else {
    hideSpinner();
  }
};

export const showWhile = async <T>(
  promise: Promise<T>,
  msg = "Vennligst vent..."
): Promise<T> => {
  push(msg);
  try {
    return await promise;
  } finally {
    pop();
  }
};

// --------------------------------------------------
// Shim
// --------------------------------------------------
if ("ajaxLoader" in window) {
  const shim: any = () => {
    throw new Error(
      "ajaxLoader no longer supported. Use FDV.utils.spinner.pop/push"
    );
  };

  shim.push = (msg = "Vennligst vent...") => {
    deprecated(shim.push, "ajaxLoader.push", "FDV.utils.spinner.push");
    return push(msg);
  };

  shim.pop = () => {
    deprecated(shim.pop, "ajaxLoader.pop", "FDV.utils.spinner.pop");
    return pop();
  };

  shim.info = (msg) => {
    deprecated(shim.info, "ajaxLoader.info", "FDV.utils.spinner.push");
    if (exists()) {
      updateText(msg);
    }
  };

  (window as any).ajaxLoader = shim;
}
