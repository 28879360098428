(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("pages.login", [], factory);
	else if(typeof exports === 'object')
		exports["pages.login"] = factory();
	else
		root["FDV"] = root["FDV"] || {}, root["FDV"]["pages.login"] = factory();
})(window, function() {
return 