import deprecated from '../deprecated';
import { IDialog, cancel, close, create, ok } from './dialog';

let open = false;
(window as any).Dialog = {
  IFrame: {
    Create(url: string, args: any) {
      if (!args) { args = {}; }
      args._old = true;
      deprecated((window as any).Dialog.Create, 'Dialog.Iframe.Create', 'FDV.dialog.create');
      const dialog = create(url);
      const self = {
        // tslint:disable-next-line:ban-types
        addEvents(evts: { [name: string]: Function }) {
          Object.keys(evts).forEach((n) => dialog.on(n, evts[n] as any));
          return self;
        },

        // tslint:disable-next-line:ban-types
        addEvent(evt: string, fn: Function) {
          dialog.on(evt, fn as any);
          return self;
        },

        open() {
          if (open) {
            // if a dialog is already open at this window, ignore this open call.
            return;
          }

          open = true;
          dialog.once('close', () => {
            open = false;
          });

          dialog.show(args);
          return self;
        },
      };

      return self;
    },
  },

  Ok(vals: any[] | any) {
    deprecated((window as any).Dialog.Ok, 'Dialog.Iframe.Ok', 'FDV.dialog.ok');
    if (!Array.isArray(vals)) { vals = Array.of(vals); }
    ok(null, null, null, ...vals);
  },

  Cancel() {
    deprecated((window as any).Dialog.Cancel, 'Dialog.Iframe.Cancel', 'FDV.dialog.cancel');
    cancel();
  },

  Close() {
    deprecated((window as any).Dialog.Close, 'Dialog.Iframe.Close', 'FDV.dialog.close');
    close();
  },
};
