import { ICell } from '../cell';
import { FDVwebClient } from './fdvwebClient';
import { defaultHandler } from './helpers';
import { IHttpHandler, IHttpMiddleware, IRequestDescriptor } from './types';

// Angular scope
export interface IAngularPromiseService {
  resolve<T>(v: T | Promise<T>): Promise<T>;
}

export class AngularFDVwebClient extends FDVwebClient {
  private _q: IAngularPromiseService;

  constructor(
    token: ICell<string>,
    $q: IAngularPromiseService,
    middleware: ReadonlyArray<IHttpMiddleware> = [],
    handler: IHttpHandler = defaultHandler,
  ) {
    super(token, middleware, handler);

    this._q = $q;
  }

  public send(request: IRequestDescriptor) {
    return this._q.resolve(super.send(request));
  }
}
