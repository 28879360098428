import { BaseError } from '../error';
import { IRequestDescriptor } from './types';

export class HttpError extends BaseError {
  public status: number;
  public response: Response | undefined;
  public request: IRequestDescriptor | undefined;

  constructor(
    status: number,
    message: string,
    request?: IRequestDescriptor,
    response?: Response,
    cause?: Error,
    statusText: string | null = null,
  ) {
    statusText = statusText === null ? (response ? ` ${response.statusText}` : '') : ` ${statusText}`;
    super(`${message}: ${status}${statusText}`, cause);

    this.status = status;
    this.request = request;
    this.response = response;
  }
}
