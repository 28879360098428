export const lazy = <T> (fn: () => T): () => T => {
  const thunk = fn;
  let get = () => {
    const value = thunk();
    get = () => value;
    return value;
  };

  return () => get();
};
