import { createHandler, decodeIfJson, defaultHandler } from './helpers';
import {
  IHttpClient,
  IHttpHandler,
  IHttpMiddleware,
  IRequestConfig,
  IRequestDescriptor,
} from './types';

export class HttpClient implements IHttpClient {
  private _handler: IHttpHandler;

  constructor(
    middleware: ReadonlyArray<IHttpMiddleware> = [],
    handler: IHttpHandler = defaultHandler,
  ) {
    if (middleware.length) {
      handler = createHandler(middleware, handler);
    }

    this._handler = handler;
  }

  public send(request: IRequestDescriptor) {
    return this._handler(request);
  }

  public get<T>(url: string, init: IRequestConfig = {}) {
    const req = {
      ...init,
      method: 'get',
      url,
    };

    return this.send(req).then(decodeIfJson(req)) as Promise<T>;
  }

  public post<T>(url: string, init: IRequestConfig = {}) {
    const req = {
      ...init,
      method: 'post',
      url,
    };

    return this.send(req).then(decodeIfJson(req)) as Promise<T>;
  }

  public delete<T>(url: string, init: IRequestConfig = {}) {
    const req = {
      ...init,
      method: 'delete',
      url,
    };

    return this.send(req).then(decodeIfJson(req)) as Promise<T>;
  }
}
