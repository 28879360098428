import Tunnel from "./tunnel";

const newId = (() => {
  let nextId = 1;
  return () => nextId++;
})();

const dialogs: DialogController[] = [];
const top = () => {
  if (dialogs.length === 0) {
    throw new Error("No dialogs opened");
  }

  return dialogs[dialogs.length - 1];
};

const nextZIndex = () => {
  let max = 10;
  const elms = Array.prototype.slice.apply(
    document.querySelectorAll("*")
  ) as HTMLElement[];
  for (const elm of elms) {
    const zIndexStr = document.defaultView
      .getComputedStyle(elm, void 0)
      .getPropertyValue("z-index");
    const zIndex = parseInt(zIndexStr || "-1", 10);
    if (!isNaN(zIndex) && zIndex > max) {
      max = zIndex;
    }
  }

  return max + 1;
};

export interface IDialogInfo {
  readonly index: number;
  readonly id: number;
  readonly frameId: number;
}

export class DialogController {
  public static ok(...vals: any[]) {
    top().close("ok", ...vals);
  }

  public static cancel() {
    top().close("cancel");
  }

  public static close() {
    top().close();
  }

  private tunnel: Tunnel;
  private url: string | null = null;
  private parentWindowName: string | null = null;
  private frame: HTMLIFrameElement | null = null;
  private _id: number = newId();
  private _arg: any = null;
  private _extra: IDialogInfo | null = null;
  private _overflow: string | null = null;

  constructor(tunnel: Tunnel) {
    this.tunnel = tunnel;
    tunnel.handler = ({ name, args }) => {
      (this as any)[name](...args);
    };
  }

  public emit(name: string, ...args: any[]) {
    this.tunnel.send({ name, args });
    return this;
  }

  public setUrl(url: string) {
    this.url = url;
  }

  public show(arg?: any) {
    if (this.frame) {
      return;
    }

    const url = this.url;
    if (url === null) {
      throw new Error(`Url not set`);
    }

    //For å sikre oss at det ikke blir åpnet flere
    //dialoger på samme dialog samtidig. (Ved hurtig klikk på knapp som åpner dialog)
    if (dialogs.length > 0 && top().parentWindowName === arg.windowName) {
      return;
    }

    this.parentWindowName = arg.windowName;

    const frameId = newId();
    if (arg) {
      this._arg = arg;
    } else {
      this._arg = null;
    }
    this._extra = {
      frameId,
      id: this._id,
      index: dialogs.length,
    };

    const frame = (this.frame = document.createElement("iframe"));
    if ("allowTransparency" in frame) {
      (frame as any).allowTransparency = true;
    }

    frame.name = `_fdv_dialog:${this._id}`;
    frame.src = url;
    frame.style.height = "0px";
    frame.style.width = "0px";
    frame.style.border = "none";
    frame.style.position = "fixed";
    frame.style.top = "0px";
    frame.style.left = "0px";
    frame.style.zIndex = nextZIndex().toString();
    frame.className = "fdv-dialog";
    frame.setAttribute("data-dialog-id", this._id.toString());
    frame.setAttribute("data-frame-id", frameId.toString());

    this._overflow = document.body.style.overflow;
    document.body.appendChild(frame);

    // NB: forventer at erMobil ligger globalt på FDV. window.FDV.erMobil
    const FDVtmp = (window as any).FDV;

    if (FDVtmp && FDVtmp.erMobil) {
      document.body.style.overflow = "hidden";
    }

    const body = document.querySelector("body");
    const hasVerticalScrollbar =
      body && body?.scrollHeight > window.innerHeight;
    if (
      hasVerticalScrollbar &&
      !FDVtmp.erMobil &&
      !FDVtmp.deaktiverScrollHide
    ) {
      body?.classList.add("lock-scroll");
    }

    const onLoad = () => {
      // frame.removeEventListener('load', onLoad);
      frame.style.width = "100vw";
      frame.style.height = "100vh";
    };

    window.addEventListener("message", this._onMessage);
    frame.addEventListener("load", onLoad);
    dialogs.push(this);
  }

  public close(evt?: string, ...args: any[]) {
    const frame = this.frame;
    if (!frame) {
      return;
    }

    const index = dialogs.indexOf(this);
    dialogs.splice(index, 1);
    frame.parentElement!.removeChild(frame);
    document.body.style.overflow = this._overflow || "";
    if (index < 1) {
      document.querySelector("body")?.classList.remove("lock-scroll");
    }
    window.removeEventListener("message", this._onMessage);
    this.frame = null;
    this.emit("close");

    if (evt) {
      this.emit(evt, ...args);
    }
  }

  private _onMessage = (evt: MessageEvent) => {
    if (
      typeof evt.data === "string" &&
      evt.data === `!dialog-${this._id}-ready!`
    ) {
      evt.ports[0].postMessage({ args: this._arg, extra: this._extra });
    }

    // TODO: Fix globally
    // tslint:disable-next-line:semicolon
  };
}
