// tslint:disable-next-line:ban-types
const shown = new Set<Function>();

// tslint:disable-next-line:no-namespace
declare namespace process {
  export const env: { [name: string]: string | undefined };
}

// tslint:disable-next-line:ban-types
const deprecated = (fn: Function, name: string, replacement?: string) => {
  if (process.env.NODE_ENV === 'development') {
    if (!shown.has(fn)) {
      shown.add(fn);
      const extra = replacement ? ` Use ${replacement} instead.` : '';
      // tslint:disable-next-line:no-console
      console.warn(`Deprecated: ${name} is deprecated.${extra}`);
    }
  }
};

export default deprecated;
