import { HttpError } from './httpError';
import { IRequestDescriptor, IServerErrorDescriptor } from './types';

export class ServerError extends HttpError {
  public data: IServerErrorDescriptor;

  constructor(
    status: number,
    message: string,
    request: IRequestDescriptor,
    response: Response,
    data: IServerErrorDescriptor,
  ) {
    super(status, message, request, response);
    this.data = data;
  }
}
