import { postToWindow } from './post';

const newId = (() => {
  let nextId = 1;
  return () => nextId++;
})();

export default class Tunnel {
  public static connect(
    win: Window,
    type: string,
    handler: (data: any, tunnel: Tunnel) => void,
  ): Promise<Tunnel> {
    return new Promise<Tunnel>((resolve) => {
      const { port1, port2 } = new MessageChannel();
      const reqId = newId();
      const fn = (evt: MessageEvent) => {
        if (
          evt.data &&
          evt.data.type === '__tunnel_acc' &&
          evt.data.reqId === reqId
        ) {
          window.removeEventListener('message', fn);
          resolve(new Tunnel(port1, handler));
        }
      };
      window.addEventListener('message', fn);
      postToWindow(win, { type: '__tunnel_open', reqId, channel: type }, [
        port2,
      ]);
    });
  }

  public static listen(type: string, fn: (tunnel: Tunnel) => void) {
    window.addEventListener('message', (evt) => {
      if (
        evt.data &&
        evt.data.type === '__tunnel_open' &&
        evt.data.channel === type
      ) {
        const port = evt.ports && evt.ports[0];
        if (!port) {
          // tslint:disable-next-line:no-console
          console.warn('Received tunnel request without ports attached');
        }

        const tunnel = new Tunnel(port);
        postToWindow(evt.source!, {
          reqId: evt.data.reqId,
          type: '__tunnel_acc',
        });
        fn(tunnel);
      }
    });
  }

  private _id: number = newId(); // for debugging purposes
  private _port: MessagePort;
  private _handler: (evt: MessageEvent) => void;
  private _fn: ((data: any, tunnel: Tunnel) => void) | null;
  private constructor(
    port: MessagePort,
    fn: ((data: any, tunnel: Tunnel) => void) | null = null,
  ) {
    this._fn = fn;
    this._port = port;
    this._handler = (evt) => {
      if (this._fn) {
        // tslint:disable-next-line:no-shadowed-variable
        const fn = this._fn;
        fn(evt.data, this);
      }
    };
    this._port.addEventListener('message', this._handler);
    this._port.start();
  }

  public send(data: any) {
    this._port.postMessage(data);
  }

  public close() {
    this._port.close();
  }

  public set handler(fn: (data: any, tunnel: Tunnel) => void) {
    this._fn = fn;
  }
}
