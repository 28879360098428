import domready from 'domready';
import { EventEmitter } from 'eventemitter3';

import { DialogController, IDialogInfo } from './controller';
import { postToWindow } from './post';
import { DialogProxy } from './proxy';
import Tunnel from './tunnel';

let _dialogArgs: any = null;
let _isDialog: boolean = false;

if (window.parent === window) {
  // this is the top window
  Tunnel.listen('dialog', (tunnel) => {
    return new DialogController(tunnel);
  });

  window.addEventListener('message', (evt) => {
    if (typeof evt.data === 'string' && evt.data.startsWith('!dialog!')) {
      const json = evt.data.substr('!dialog!'.length);
      const data = JSON.parse(json);
      if (data && data.type === '__global_dialog') {
        const { name, args } = data;
        (DialogController as any)[name](...args);
      }
    }
  });
} else {
  domready(() => {
    const match = /_fdv_dialog:(\d+)$/.exec(window.name);
    if (match) {
      _isDialog = true;
      const dialogId = match[1];
      const channel = new MessageChannel();
      channel.port1.addEventListener('message', (evt) => {
        channel.port1.close();
        const { args, extra } = evt.data;
        _dialogArgs = args;
        _evt.emit('ready', args, extra);
      });
      channel.port1.start();
      postToWindow(window.parent, `!dialog-${dialogId}-ready!`, [
        channel.port2,
      ]);
    }
  });
}

export interface IDialog extends EventEmitter {
  show(arg?: any): this;
  close(): this;
  toPromise(): Promise<any[]>;
  toSafePromise(): Promise<void>;
}

export const create = (url: string) => new DialogProxy(url) as IDialog;

const createGlobalCall = (name: string) => (...args: any[]) => {
  window.parent.postMessage(
    '!dialog!' +
    JSON.stringify({
      args,
      name,
      type: '__global_dialog',
    }),
    '*',
  );
};

const _evt = new EventEmitter();

export const ok = createGlobalCall('ok');
export const cancel = createGlobalCall('cancel');
export const close = createGlobalCall('close');

export const args = () => _dialogArgs;
export const isDialog = () => _isDialog;
export const ready = (fn: (args: any, extra: IDialogInfo) => void) =>
  _evt.on('ready', fn);
