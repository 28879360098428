const DONE = 'complete';

let listeners: Array<() => void> | null = null;

const runAfterLoad = (fn: () => void) => {
  const doc = window.document;

  if (doc.readyState === DONE) {
    fn();
    return;
  }

  register(fn);
};

const update = () => {
  const doc = window.document;

  if (doc.readyState === DONE) {
    unregister();
    trigger();
  }
};

const register = (fn: () => void) => {
  const doc = window.document;
  const win = window;

  if (listeners === null) {
    doc.addEventListener('DOMContentLoaded', update, false);
    doc.addEventListener('readystatechange', update, false);
    win.addEventListener('load', update, false);
    listeners = [];
  }

  listeners.push(fn);
};

const unregister = () => {
  const doc = window.document;
  const win = window;

  doc.removeEventListener('DOMContentLoaded', update, false);
  doc.removeEventListener('readystatechange', update, false);
  win.removeEventListener('load', update, false);
};

const trigger = () => {
  if (!listeners) return;
  for (const fn of listeners) {
    fn();
  }

  listeners = null;
};

export default runAfterLoad;
