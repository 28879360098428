import { cell } from '../cell';
import {
  AngularFDVwebClient,
  IAngularPromiseService,
} from './angularFdvwebClient';
import { FDVwebClient } from './fdvwebClient';
import { HttpClient } from './httpClient';
import * as middleware from './middleware';

const _token = cell<string>('token');

export const setToken = (token: string) => {
  _token.set(token);
};

export const createClient = () => new FDVwebClient(_token, middleware.standard);
export const createAngularClient = ($q: IAngularPromiseService) =>
  new AngularFDVwebClient(_token, $q, middleware.standard);
export const createNoAuth = () => new HttpClient();

const tokenHolder = document.querySelector(
  'meta[itemprop="fdv:token"]',
) as HTMLMetaElement | null;
if (tokenHolder) {
  setToken(tokenHolder.content);
}
