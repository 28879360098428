import axios from 'axios';

import { lazy } from '../lazy';
import { HttpError } from './httpError';
import { IHttpHandler, IHttpMiddleware, IRequestDescriptor } from './types';

const supports = {
  formData: 'FormData' in window,
};

export const is = {
  // Array test
  array: (v: any): v is any[] => Array.isArray(v),

  // FormData test
  formData: supports.formData
    ? (v: any) => v instanceof FormData
    : (_: any) => false,

  // Plain JavaScript Object test
  pojo: (v: any) => Object.getPrototypeOf(v) === Object.prototype,
};

const getAxios = lazy(async () => {
  // tslint:disable-next-line:whitespace
  // const axios = (await import('axios')).default;
  axios.defaults.transformResponse = [];

  const isResponseTypeSupported = (
    responseType: XMLHttpRequestResponseType,
  ) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/');

    if (typeof xhr.responseType !== 'string') {
      return false;
    }

    try {
      xhr.responseType = responseType;
    } catch (e) {
      return false;
    }

    return xhr.responseType === responseType;
  };

  const firstSupported = (...types: XMLHttpRequestResponseType[]) => {
    for (const type of types) {
      if (isResponseTypeSupported(type)) {
        return type;
      }
    }

    return '';
  };

  const responseType: XMLHttpRequestResponseType = firstSupported(
    'blob',
    'arraybuffer',
  );

  return [axios, responseType] as [typeof axios, XMLHttpRequestResponseType];
});

const axiosHandler: IHttpHandler = ({ url, ...init }) =>
  getAxios()
    .then(([axios, responseType]) =>
      axios({
        cancelToken: init.cancelToken,
        data: init.body,
        headers: init.headers,
        method: init.method,
        onDownloadProgress: init.downloadProgress,
        onUploadProgress: init.uploadProgress,
        responseType,
        transformResponse: [],
        url,
        validateStatus: () => true,
      }),
    )
    .then(result => new Response(result.data, result));

const fetchHandler: IHttpHandler = ({ url, ...init }) => fetch(url, init);

export const defaultHandler: IHttpHandler = (req: IRequestDescriptor) =>
  req.downloadProgress || req.uploadProgress
    ? axiosHandler(req)
    : fetchHandler(req);

export const createHandler = (
  mws: ReadonlyArray<IHttpMiddleware>,
  base: IHttpHandler,
): IHttpHandler => mws.reduceRight((handler, mw) => mw(handler), base);

export const decodeIfJson = (req: IRequestDescriptor) => (
  res: Response,
): Promise<any> => {
  const contentType = res.headers.get('content-type');
  if (
    contentType &&
    contentType.toLowerCase().indexOf('application/json') !== -1
  ) {
    return res.json().catch(e => {
      throw new HttpError(
        600,
        'Response failed to parse as JSON',
        req,
        res,
        e,
        'NOT JSON',
      );
    });
  }

  return res.text();
};
