// -------------------------------------------------------------------

// tslint:disable-next-line:ban-types
const captureStackTrace: (e: Error, fn?: Function) => void = (() => {
  const native = (Error as any).captureStackTrace;
  if (native) {
    return native;
  }

  return function captureStackTrace(error: Error) {
    const container = new Error();

    Object.defineProperty(error, 'stack', {
      configurable: true,
      get: function getStack() {
        const stack = container.stack;

        // Replace property with value for faster future accesses.
        Object.defineProperty(error, 'stack', {
          configurable: true,
          value: stack,
          writable: true,
        });

        return stack;
      },

      set: function setStack(stack) {
        Object.defineProperty(error, 'stack', {
          configurable: true,
          value: stack,
          writable: true,
        });
      },
    });
  };
})();

// -------------------------------------------------------------------

function ErrorShim(this: Error, ...args: any[]) {
  const self = this;
  const error = Error.apply(this, args);
  Object.setPrototypeOf(error, Object.getPrototypeOf(self));
  return error;
}
ErrorShim.prototype = Object.create(Error.prototype);

export class BaseError extends (ErrorShim as typeof Error) {
  public cause: Error | undefined;

  constructor(message?: string, cause?: Error) {
    super(message);

    if (message) {
      Object.defineProperty(this, 'message', {
        configurable: true,
        value: message,
        writable: false,
      });
    }

    if (cause) {
      Object.defineProperty(this, 'cause', {
        configurable: true,
        value: cause,
        writable: false,
      });
    }

    const cname = this.constructor.name;
    if (cname && cname !== this.name) {
      Object.defineProperty(this, 'name', {
        configurable: true,
        value: cname,
        writable: false,
      });
    }

    captureStackTrace(this, this.constructor);

    if (cause) {
      this.stack += '\n\nCaused by: ' + (cause.stack || cause.message);
    }
  }
}

Object.defineProperty(BaseError.prototype, 'constructor', {
  configurable: true,
  value: BaseError,
  writable: true,
});
