import { isNullOrUndefined } from "util";

import { detect as detectBrowser } from "detect-browser";
import domready from "domready";

import { create, isDialog, ready } from "../../dialog";
import { id } from "../../utils/dom";
import { createNoAuth } from "../../utils/http";
import { IRequestConfig } from "../../utils/http/types";
import runAfterLoad from "../../utils/onload";

const LOGIN_KEY = "FDV.login.username";
const LOGIN_KEY_ST = "FDV.login.usernameServictorg";
const LOGIN_KEY_VER7 = "login_name";
const LOGIN_KEY_SERVICETORGBRUKER = "FDV.login.servicetorgbruker";

// declare const getOversettelse: (text: string) => string;
const getOversettelse: (text: string) => string = (v) => v;

const elm = <T extends Element>(name: string) => {
  const e = id<T>(name);
  if (!e) {
    throw new Error(`Element by id ${name} not found.`);
  }

  return e;
};

const client = createNoAuth();

const parseVersion = (version: string) => {
  const versionRegex = /(\d+)(\.\d+)?/;
  const match = versionRegex.exec(version);
  if (!match) return null;
  return parseFloat(match[0]);
};

const handleEnter = (elmToRegister: HTMLElement, fn: () => void) => {
  elmToRegister.addEventListener("keydown", (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      fn();
    }
  });
};


const getFormData = (form: HTMLFormElement): IRequestConfig => {
  const formData = new FormData(form);
  const searchParams = new URLSearchParams();

  const entries = formData.entries();

  while(true){
    const next = entries.next();
    if(next.done) break;
    const [name, value] = next.value;
    searchParams.append(name, value as string);
  }

  return {
    body: searchParams.toString(),
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
};

type BrukerOgGruppe = { brukerNavn: string; epost: string; gruppe: string; id: number };
type LoginResult =
  | { type: "success"; redirectTo: string }
  | { type: "newPassword"; passwordExpired: boolean }
  | { type: "requireTwoFACode" }
  | { type: "activateTwoFACode"; redirectTo: string }
  | { type: "error"; errors: { [name: string]: string[] | undefined } }
  | { type: "duplikatEpost"; brukere: BrukerOgGruppe[]};

function getCookie(name: string): string {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || ""
  );
}

domready(() => {
  const nyhetInfo = elm<HTMLDivElement>("nyhetInfo");
  const loginKnapp = elm<HTMLButtonElement>("loginKnapp");
  const loginTwoFAKnapp = elm<HTMLButtonElement>("loginTwoFAKnapp");
  const loginDuplikatEpostKnapp = elm<HTMLButtonElement>("loginDuplikatEpostKnapp");
  const loginForm = elm<HTMLFormElement>("loginForm");
  const changePasswordForm = elm<HTMLFormElement>("changePasswordForm");
  const activateTwoFAForm = elm<HTMLFormElement>("activateTwoFAForm");
  const duplikatEpostForm = elm<HTMLFormElement>("duplikatEpostForm");
  const dialogHovedDiv = elm<HTMLDivElement>("dialogHovedDiv");
  const nyttPassordInfoDiv = elm<HTMLDivElement>("nyttPassordInfoDiv");
  const glemtPassord = elm<HTMLAnchorElement>("glemtPassord");
  const navnFelt = elm<HTMLInputElement>("username");
  const passordFelt = elm<HTMLInputElement>("password");
  const newpassord = elm<HTMLInputElement>("newpassord");
  const confirmpassword = elm<HTMLInputElement>("confirmpassword");
  const rememberLogin = elm<HTMLInputElement>("RememberLogin");
  const changeFormUsername = elm<HTMLInputElement>("changeFormUsername");
  const changeFormPassword = elm<HTMLInputElement>("changeFormPassword");
  const twoFAForm = elm<HTMLFormElement>("twoFAForm");
  const twoFAFormUsername = elm<HTMLInputElement>("twoFAFormUsername");
  const twoFAFormPassword = elm<HTMLFormElement>("twoFAFormPassword");
  const twoFAFormUserId =  elm<HTMLInputElement>("twoFAFormUserId");
  const duplicateUsername = elm<HTMLInputElement>("duplicateUsername");
  const duplicatePassword = elm<HTMLInputElement>("duplicatePassword");
  // const duplicateUserId = elm<HTMLInputElement>("duplicateUserId");
  const duplicateRememberLogin = elm<HTMLInputElement>("duplicateRememberLogin");
  const duplikatListe = elm<HTMLDivElement>("duplikatListe");

  const activateTwoFAFormReturnUrl = elm<HTMLInputElement>(
    "activateTwoFAFormReturnUrl"
  );
  const activateTwoFAFormUsername = elm<HTMLInputElement>(
    "activateTwoFAFormUsername"
  );
  const activateTwoFAFormPassword = elm<HTMLInputElement>(
    "activateTwoFAFormPassword"
  );
  const engangskode = elm<HTMLInputElement>("engangskode");
  const twoFAFormRememberLogin = elm<HTMLInputElement>(
    "twoFAFormRememberLogin"
  );
  const servicetorgLogin = id<HTMLSpanElement>("servicetorgLogin");
  const fdvwebLogin = id<HTMLSpanElement>("fdvwebLogin")!;
  const nyServicetorgBruker = id<HTMLSpanElement>("nyServicetorgBruker")!;
  const nyServicetorgbrukerKnapp = id<HTMLButtonElement>(
    "nyServicetorgbrukerKnapp"
  );
  const sideTittel = elm<HTMLHtmlElement>("side-tittel");
  const sideHeader1 = elm<HTMLHtmlElement>("sideHeader1");
  const redigerToppBarTittelTekst = id<HTMLHtmlElement>("rediger-topp-bar__tittel_tekst");
  const sideHeader2 = elm<HTMLHtmlElement>("sideHeader2");
  const brukerNavn = elm<HTMLElement>("brukerNavn");
  const visServicetorgLogin =
    elm<HTMLInputElement>("visServicetorgLogin").value === "true"
      ? true
      : false;

  const ikkeKonverterteServicetorgbrukere =
    elm<HTMLInputElement>("ikkeKonverterteServicetorgbrukere").value === "true"
      ? true
      : false;

  const brukerGruppeFilterType = id<HTMLInputElement>("BrukerGruppeFilterType");
  const twoFAFormBrukerGruppeFilterType = id<HTMLInputElement>("twoFAFormBrukerGruppeFilterType");
  const changeFormBrukerGruppeFilterType = id<HTMLInputElement>("changeFormBrukerGruppeFilterType");

  let nyUrlPrefix = "";
  const nyUrlPrefixElm = id<HTMLInputElement>("urlPrefix");
  if (nyUrlPrefixElm != null) 
    nyUrlPrefix = nyUrlPrefixElm.value === "true" ? ".." : "";

  let domeneMappe = "";
  const domeneMappeElm = id<HTMLInputElement>("domeneMappe");
  if (domeneMappeElm != null) 
    domeneMappe = domeneMappeElm.value ?? "";

  let erServiceTorgBruker =
    localStorage.getItem(LOGIN_KEY_SERVICETORGBRUKER) === "true" ? true : false;

  if (visServicetorgLogin) {
    erServiceTorgBruker = true;
  }

  if (erServiceTorgBruker) {
    if (brukerGruppeFilterType != null) brukerGruppeFilterType.value = "Servicetorg";
    if (twoFAFormBrukerGruppeFilterType != null) twoFAFormBrukerGruppeFilterType.value = "Servicetorg";
    if (changeFormBrukerGruppeFilterType != null) changeFormBrukerGruppeFilterType.value = "Servicetorg";
  } else {
    if (brukerGruppeFilterType != null) brukerGruppeFilterType.value = "FDVweb";
    if (twoFAFormBrukerGruppeFilterType != null) twoFAFormBrukerGruppeFilterType.value = "FDVweb";
    if (changeFormBrukerGruppeFilterType != null) changeFormBrukerGruppeFilterType.value = "FDVweb";
  }

  const sjekkServicetorgBruker = async (brukerNavn: string) => {
    const stMailAdresse = await client.get<string>(
      nyUrlPrefix + "/loggInn/BrukerKonverteringSjekk?brukerNavn=" + brukerNavn
    );

    if (!isNullOrUndefined(stMailAdresse) && stMailAdresse !== "") {
      const konverter = confirm(
        "Brukerprofilen til " +
        brukerNavn +
        " må oppgraderes fordi det er laget ny policy på domenet der alle brukere skal benytte passord.\n\n" +
        "Nytt midlertidig passord vil bli sendt til E-post adresse: " +
        stMailAdresse +
        ".\n\n" +
        "Passordet benyttes ved innlogging.\n\nØnsker du å konvertere profilen nå?"
      );

      if (konverter) {
        const erKonvertert = await client.get<string>(
          nyUrlPrefix + "/loggInn/KonverteringServicetorgbruker?brukerNavn=" + brukerNavn
        );

        if (erKonvertert) {
          alert(
            "Brukeren er nå konvertert, og nytt passord er sendt til E-post adresse. " +
            stMailAdresse +
            "\n\nBenytt passordet for å logge deg inn."
          );
          passordFelt.focus();
        } else {
          alert("Noe gikk galt. Kontakt leverandør av FDVweb for bistand.");
        }
      }
    }
  };

  const login = async (formName?: string) => {
    if (!formName) {
      if (!loginForm.classList.contains("hidden")) {
        formName = "loginForm";
      } else if (!changePasswordForm.classList.contains("hidden")) {
        formName = "changePasswordForm";
      } else if (!twoFAForm.classList.contains("hidden")) {
        formName = "twoFAForm";
      } else if (!duplikatEpostForm.classList.contains("hidden")) {
        formName = "duplikatEpostForm";
      }
    }

    const form = id(formName!) as HTMLFormElement;
    const setNewPassword = id("setNewPassword") as HTMLInputElement;
    const utloptPassordInfoDiv = id("utloptPassordInfoDiv") as HTMLDivElement;
    const bekreftPassordDiv = id("bekreftPassordDiv") as HTMLDivElement;
    const brukernavnDiv = id("brukernavnDiv") as HTMLDivElement;
    const passordDiv = id("passordDiv") as HTMLDivElement;
    if (
      formName === "changePasswordForm" &&
      setNewPassword &&
      newpassord.value !== confirmpassword.value
    ) {
      alert("Verdiene i nytt passord og bekreft passord er ikke like");
      return;
    }

    if(formName === "duplikatEpostForm"){
      const data = new FormData(form);

      if(!data.has("UserId"))
      {
        alert("Du må velge hvilken bruker du skal logge inn med");
        return;
      }
      else {
        twoFAFormUserId.value = data.get("UserId");
      }
    }

    const result = await client.post<LoginResult>(
      form.action,
      getFormData(form)
    );

    if (result.type === "success") {
      // Skriver brukernavn som localstorage
      if (erServiceTorgBruker) {
        localStorage.setItem(
          LOGIN_KEY_ST + domeneMappe,
          (id("username") as HTMLInputElement).value
        );
      } else {
        localStorage.setItem(
          LOGIN_KEY +  domeneMappe,
          (id("username") as HTMLInputElement).value
        );
      }

      window.location.replace(result.redirectTo);
    } else if (result.type === "duplikatEpost") {
      duplicateUsername.value = navnFelt.value;
      duplicatePassword.value = passordFelt.value;
      duplicateRememberLogin.value = "" + rememberLogin.checked;
      loginForm.classList.add("hidden");
      duplikatEpostForm.classList.remove("hidden");
      
      lagBrukerListe(result.brukere, duplikatListe);



    } else if (result.type === "newPassword") {
      changeFormUsername.value = navnFelt.value;
      changeFormPassword.value = passordFelt.value;
      loginForm.classList.add("hidden");
      twoFAForm.classList.add("hidden");
      changePasswordForm.classList.remove("hidden");
      if (result.passwordExpired) {
        utloptPassordInfoDiv.classList.remove("hidden");
      } else {
        nyttPassordInfoDiv.classList.remove("hidden");
      }
      nyhetInfo.classList.add("hidden");
    } else if (result.type === "requireTwoFACode") {
      twoFAFormUsername.value = navnFelt.value;
      twoFAFormPassword.value = passordFelt.value;
      twoFAFormRememberLogin.value = "" + rememberLogin.checked;
      loginForm.classList.add("hidden");
      duplikatEpostForm.classList.add("hidden");
      twoFAForm.classList.remove("hidden");
      engangskode.focus();
    } else if (result.type === "activateTwoFACode") {
      activateTwoFAFormUsername.value = navnFelt.value;
      activateTwoFAFormPassword.value = passordFelt.value;
      activateTwoFAFormReturnUrl.value = result.redirectTo;
      activateTwoFAForm.submit();
    } else {
      if (result.errors && result.errors[""] && result.errors[""]![0]) {
        alert(result.errors[""]![0]);
      }
      // TODO: Vis feil bedre
    }
  };

  if (servicetorgLogin && erServiceTorgBruker) {
    sideHeader1.innerHTML = "Servicetorg";

    if (redigerToppBarTittelTekst) {
      redigerToppBarTittelTekst.innerHTML = "Servicetorg";
    }

    sideHeader2.innerHTML = "";
    brukerNavn.innerHTML = "Navn/epost";
  }

  if (erServiceTorgBruker) {
    navnFelt.value =
      localStorage.getItem(LOGIN_KEY_ST + domeneMappe) || getCookie(LOGIN_KEY_VER7) || "";
  } else {
    navnFelt.value =
      localStorage.getItem(LOGIN_KEY + domeneMappe) || getCookie(LOGIN_KEY_VER7) || "";
  }

  if (
    erServiceTorgBruker &&
    navnFelt.value !== "" &&
    ikkeKonverterteServicetorgbrukere
  ) {
    runAfterLoad(() => {
      // kjører på onload
      sjekkServicetorgBruker(navnFelt.value);
    });
  }

  if (
    erServiceTorgBruker &&
    nyServicetorgbrukerKnapp &&
    navnFelt.value === ""
  ) {
    nyServicetorgbrukerKnapp.classList.remove("hidden");
  }

  navnFelt.addEventListener("change", () => {
    if (navnFelt.value.trim() === "") {
      glemtPassord.classList.add("hidden");

      if (erServiceTorgBruker && nyServicetorgbrukerKnapp) {
        nyServicetorgbrukerKnapp.classList.remove("hidden");
      }
    } else {
      glemtPassord.classList.remove("hidden");

      if (nyServicetorgbrukerKnapp) {
        nyServicetorgbrukerKnapp.classList.add("hidden");
      }

      if (erServiceTorgBruker && ikkeKonverterteServicetorgbrukere) {
        sjekkServicetorgBruker(navnFelt.value);
      }
    }
  });

  glemtPassord.addEventListener("click", () => {
    if (navnFelt.value.trim() === "") {
      alert(
        getOversettelse(
          "Du må skrive inn brukernavn til kontoen du ønsker å endre passord til"
        )
      );
      return;
    }

    let brukerGruppeFilterType = "FDVweb";

    if (erServiceTorgBruker) {
      brukerGruppeFilterType = "Servicetorg";
    }

    create(nyUrlPrefix + "/Logginn/GlemtPassordDialog?brukerNavn=" + navnFelt.value + "&brukerGruppeFilter=" + brukerGruppeFilterType)
      .on("ok", () => {
        // Her kommer Ok funksjonaliteten.
      })
      .show();
  });

  if (nyServicetorgbrukerKnapp) {
    nyServicetorgbrukerKnapp.addEventListener("click", () => {
      window.location.href = nyUrlPrefix + "/Logginn/ServicetorgNyBruker";
    });
  }

  loginForm.style.display = "";
  loginKnapp.style.display = "";
  glemtPassord.style.direction = "";

  setTimeout(() => {
    if (navnFelt.value.trim().length > 0) {
      glemtPassord.classList.remove("hidden");
      passordFelt.focus();
    } else {
      navnFelt.focus();
    }
  }, 200);

  // Håndter enter trykk i inputer
  handleEnter(navnFelt, () => {
    passordFelt.focus();
    if (navnFelt.value.trim().length > 0) {
      glemtPassord.classList.remove("hidden");
    }
  });
  handleEnter(passordFelt, login);
  handleEnter(engangskode, () => login("twoFAForm"));

  // Håndter form submit for login form
  loginForm.addEventListener("submit", (evt) => {
    evt.preventDefault();
    login("loginForm");
  });

  // Håndter form submit for endre passord form
  changePasswordForm.addEventListener("submit", (evt) => {
    evt.preventDefault();
    login("changePasswordForm");
  });

  // Håndter form submit for 2fa form
  twoFAForm.addEventListener("submit", (evt) => {
    evt.preventDefault();
    login("twoFAForm");
  });

    // Håndter form submit for duplikat epostform
    duplikatEpostForm.addEventListener("submit", (evt) => {
      evt.preventDefault();
      login("duplikatEpostForm");
    });

  // For å kunne lettere teste dialog, så håndterer den at hvis du setter
  // test_dialog=true i query string så viser den dialog i stedet for full
  // login.
  const testDialog = /[&?]test_dialog=true(&|$)/i.test(window.location.search);
  const realDialog = isDialog();
  // Håndter dialog
  if (realDialog || testDialog) {
    document.body.classList.remove('login-full');
    document.body.classList.add('login-dialog');

    glemtPassord.classList.add("d-n");

    let isDialogReady = false;

    window.addEventListener("resize", () => {
      if (isDialogReady) {
        centerDialog();
      }
    });

    const registerReadyHandler = realDialog ? ready : runAfterLoad;
    registerReadyHandler(() => {
      isDialogReady = true;
      document.body.classList.remove('hidden');

      centerDialog();
    });

    const centerDialog = () => {
      const dialogHeight = dialogHovedDiv.offsetHeight;
      const dialogWidth = dialogHovedDiv.offsetWidth;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      const left = (windowWidth - dialogWidth) / 2;
      const top = (windowHeight - dialogHeight) / 2;
      dialogHovedDiv.style.left = `${left}px`;
      dialogHovedDiv.style.top = `${top}px`;
      dialogHovedDiv.style.position = "absolute";
    };
  } else {
    runAfterLoad(() => {
      document.body.classList.remove('hidden');
    });
  }

  const browser = detectBrowser();
  // spesial håndter ie
  if (
    browser &&
    browser.name === "ie" &&
    (parseVersion(browser.version) || 9) < 10
  ) {
    const ie9Info = id("ie9Info") as HTMLDivElement;
    ie9Info.style.display = "";
    ie9Info.focus();
    loginKnapp.disabled = true;
    navnFelt.disabled = true;
    passordFelt.disabled = true;
  }

  if (servicetorgLogin) {
    servicetorgLogin.addEventListener("click", () => {
      sideHeader1.innerHTML = "Servicetorg";
      if (redigerToppBarTittelTekst) {
        redigerToppBarTittelTekst.innerHTML = "Servicetorg";
      }
      sideHeader2.innerHTML = "";
      brukerNavn.innerHTML = "Navn/epost";
      navnFelt.value = localStorage.getItem(LOGIN_KEY_ST + domeneMappe) || "";
      passordFelt.value = "";
      localStorage.setItem(LOGIN_KEY_SERVICETORGBRUKER, String(true));
      erServiceTorgBruker = true;

      if (brukerGruppeFilterType != null) brukerGruppeFilterType.value = "Servicetorg";
      if (twoFAFormBrukerGruppeFilterType != null) twoFAFormBrukerGruppeFilterType.value = "Servicetorg";
      if (changeFormBrukerGruppeFilterType != null) changeFormBrukerGruppeFilterType.value = "Servicetorg";
    });

    fdvwebLogin.addEventListener("click", () => {
      sideHeader1.innerHTML = "FDVweb";
      if (redigerToppBarTittelTekst) {
        redigerToppBarTittelTekst.innerHTML = "FDVweb";
      }
      brukerNavn.innerHTML = "Brukernavn:";
      navnFelt.value = localStorage.getItem(LOGIN_KEY + domeneMappe) || "";
      passordFelt.value = "";
      localStorage.setItem(LOGIN_KEY_SERVICETORGBRUKER, String(false));
      erServiceTorgBruker = false;

      if (brukerGruppeFilterType != null) brukerGruppeFilterType.value = "FDVweb";
      if (twoFAFormBrukerGruppeFilterType != null) twoFAFormBrukerGruppeFilterType.value = "FDVweb";
      if (changeFormBrukerGruppeFilterType != null) changeFormBrukerGruppeFilterType.value = "FDVweb";
    });

    nyServicetorgBruker.addEventListener("click", () => {
      window.location.href = nyUrlPrefix + "/Logginn/ServicetorgNyBruker";
    });
  }
});

function lagBrukerListe(brukere: BrukerOgGruppe[], div: HTMLElement ){
  div.innerHTML = "";

  for(const bruker of brukere){
    const label = document.createElement("label");
    const input = document.createElement("input");
    input.name = "UserId";
    input.type = "radio";
    input.value = bruker.id.toString(); 
    const span = document.createElement("span");
    span.innerText = `${bruker.gruppe} - ${bruker.brukerNavn}`;

    label.appendChild(input);
    label.appendChild(span);

    div.appendChild(label);
  }

}