import { IDialog } from '../../dialog';
import { lazy } from '../lazy';
import { showWhile } from '../spinner';
import { is } from './helpers';
import { HttpError } from './httpError';
import { ServerError } from './serverError';
import {
  IHttpMiddleware,
  IRequestDescriptor,
  IServerErrorDescriptor,
} from './types';

export const serializeJsonData: IHttpMiddleware = next => req => {
  if (req.body && (is.pojo(req.body) || is.array(req.body))) {
    const body = JSON.stringify(req.body);
    if (!req.headers) {
      req.headers = {};
    }

    req.headers['Content-Type'] = 'application/json';
    req.body = body;
  }

  return next(req);
};

export const serverErrorHandler: IHttpMiddleware = next => req =>
  next(req).then(res => {

    //Meldinger som skal vises fra server
    if (res.status === 290) {
      //Cloner for at json() kan ikke leses 2 ganger
      const response2 = res.clone();
      response2.json().then((data: IServerErrorDescriptor) => {
        if (data.message) {
          alert(data.message);
          (window as any).OKtrykk = false;
        }

        // throw new ServerError(res.status, data.message, req, res, data);
      });
      return Promise.reject(290);
    } else if (res.status === 412) {
      // Something went wrong during the request, and an error message intended to
      // be handled by the client is sent from the server.
      res.json().then((data: IServerErrorDescriptor) => {
        throw new ServerError(res.status, data.message, req, res, data);
      });
    }

    return Promise.resolve(res);
  });

export const NetworkErrorHandler: IHttpMiddleware = next => req =>
  next(req).catch(res => {
    // NB: forventer at visNettverkStatus ligger globalt på FDV. window.FDV.visNettverkStatus
    const FDVtmp = (window as any).FDV;

    if (FDVtmp) {
      FDVtmp.visNettverkStatus(false);
    }
    // console.log("NETWORK_ERROR: ");
    // console.log(res);
  });

export const assertValidResponseCode: IHttpMiddleware = next => req =>
  next(req).then(res => {
    if (res.status >= 200 && res.status < 300) {
      // response is ok, 2xx status code
      return res;
    }

    throw new HttpError(res.status, `Invalid response code`, req, res);
  });

export const spinner: IHttpMiddleware = next => req => {
  if (req.spinner === false) return next(req);
  if (typeof req.spinner === 'string') return showWhile(next(req), req.spinner);
  return showWhile(next(req));
};

const lazyLoginDialogPromise = lazy(async () => {
  const dialog = await import('../../dialog'); // tslint:disable-line whitespace
  return dialog.create('/logginn/LoggInnDialog');
});
export const createAuthErrorHandler = (
  update: (token: string, req: IRequestDescriptor) => IRequestDescriptor,
): IHttpMiddleware => next => async req => {
  if (req.disableAuth) return await next(req);
  let result: Response;
  let retries = 0;

  while (true) {
    result = await next(req);
    if (result.status === 401) {
      if (retries >= 3) {
        throw new HttpError(
          result.status,
          'Got unauthorized after 3 retries.',
          req,
          result,
        );
      }
      // User is not logged in
      const dialog = await lazyLoginDialogPromise();
      const dialogResult = await dialog.show().toPromise();
      const [newToken] = dialogResult;
      req = update(newToken, req);
      retries++;
    } else {
      break;
    }
  }

  return result;
};

export const standard: ReadonlyArray<IHttpMiddleware> = Object.freeze([
  spinner,
  serializeJsonData,
  assertValidResponseCode,
  serverErrorHandler,
  NetworkErrorHandler
]);
