import { EventEmitter } from 'eventemitter3';

import { IDialog } from './dialog';
import Tunnel from './tunnel';

export class DialogProxy extends EventEmitter implements IDialog {
  private [Symbol.toStringTag] = 'Dialog';

  private tunnel: Tunnel | null = null;
  private queue: Array<{ name: string, args: any[] }> = [];

  constructor(url: string) {
    super();
    Tunnel.connect(window.parent, 'dialog', ({ name, args }) => {
      this.emit(name, ...args);
    }).then((tunnel) => {
      this.tunnel = tunnel;
      this.queue.forEach((msg) => tunnel.send(msg));
    });
    this._call('setUrl', url);
  }

  public show(arg: any = {}) {
    //Sender med window.name som argument
    //til dialog-controlleren sånn at vi
    //vet hvilken dialog som åpnet ny dialog.
    //For å sikre oss at det ikke blir åpnet flere
    //dialoger på samme dialog samtidig. 
    arg.windowName = window.name;
    return this._call('show', arg);
  }

  public close() {
    return this._call('close');
  }

  // TODO: Consolidate the two promise functions.
  public toPromise() {
    return new Promise<any[]>((resolve, reject) => {
      this.once('ok', (...args) => resolve(args))
        .once('cancel', () => reject(new Error('Dialog canceled')));
    });
  }

  public toSafePromise() {
    return new Promise<void>((resolve) => {
      this.once('close', () => resolve());
    });
  }

  private _call(name: string, ...args: any[]) {
    if (this.tunnel === null) {
      this.queue.push({ name, args });
    } else {
      this.tunnel.send({ name, args });
    }

    return this;
  }
}
