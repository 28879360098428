import { ICell } from '../cell';
import { defaultHandler } from './helpers';
import { HttpClient } from './httpClient';
import { createAuthErrorHandler } from './middleware';
import { IHttpHandler, IHttpMiddleware, IRequestDescriptor } from './types';

declare const process: any;

const knownKeys = [
  'url',
  'method',
  'headers',
  'body',
  'referrer',
  'referrerPolicy',
  'mode',
  'credentials',
  'cache',
  'redirect',
  'integrity',
  'keepalive',
  'spinner',
  'disableAuth',
];
const validate = (request: IRequestDescriptor) => {
  const keys = Object.keys(request);
  keys.forEach(key => {
    if (knownKeys.indexOf(key) === -1) {
      // tslint:disable-next-line:no-console
      console.warn(`Key ${key} used in request descriptor is not known.`);
    }
  });

  if (keys.indexOf('body') !== -1 && !request.body) {
    // tslint:disable-next-line:no-console
    console.warn('request.body was set to an empty value.');
  }
};

export class FDVwebClient extends HttpClient {
  private readonly _token: ICell<string>;

  constructor(
    token: ICell<string>,
    middleware: ReadonlyArray<IHttpMiddleware> = [],
    handler: IHttpHandler = defaultHandler,
  ) {
    const authHandler = createAuthErrorHandler((newToken, req) => {
      this._token.set(newToken);
      return this._addToken(req);
    });
    super(middleware.concat(authHandler));

    this._token = token;
  }

  public send(request: IRequestDescriptor) {
    if (process.env.NODE_ENV === 'development') {
      validate(request);
    }

    return super.send(this._addToken(request));
  }

  private _addToken(init: IRequestDescriptor): IRequestDescriptor {
    if (!init.headers) {
      init.headers = {};
    }

    // tslint:disable-next-line:no-string-literal
    init.headers['Authorization'] = `Bearer ${this._token.get()}`;

    return init;
  }
}
