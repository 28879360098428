export interface ICell<T> {
  get(): T;
  set(val: T): void;
}

export const cell = <T> (name: string): ICell<T> => {
  const self: ICell<T> = {
    get(): T { throw new Error(`${name} not set`); },
    set(val: T) { self.get = () => val; },
  };

  return self;
};
